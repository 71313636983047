import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import moment from 'moment-timezone'

import { useModal } from 'design-system/components/Modals'
import userService from 'shared/utils/user'
import LogoutWarning from 'web-client/components/LogoutWarning'
import logging from 'shared/utils/logging'
import auth0Service from 'web-client/utils/auth0'

import useCurrentUser from 'web-client/hooks/useCurrentUser'
import Consts from 'consts'
import useFeaturesEnabled from 'web-client/hooks/useFeaturesEnabled'
import sessionService from 'web-client/utils/session'
import workerTimers from 'web-client/utils/workerTimers'

const modalId = 'logout-warning-modal'
const SHOW_SESSION_TIMEOUT_IN_MILLIES = Consts.SHOW_SESSION_TIMEOUT_MODAL_IN_SECONDS * 1000

const handleContinue = () => {
  logging.logInfo('Pressed continue on session timeout modal', {})
}

const logout = () => {
  userService
    .logoutWithError('For security reasons, you have been logged out due to inactivity.')
    .catch((error: Error) => {
      logging.logWarning('Automatic session timeout logout failed', error)
    })
}

const useSessionTracker = () => {
  const lastClickTime = useRef(Date.now())
  const { openModal, modalIsOpen } = useModal()
  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser()
  const { featuresEnabled } = useFeaturesEnabled()
  const { company, roles, permissions } = currentUser

  // Determine if we want to timeout users and log them out
  const isTimeoutEnabled = useMemo(() => {
    if (isCurrentUserLoading || !roles.length || !company || !userService.isAuthenticated()) {
      return false
    }
    if (featuresEnabled?.MultiFactorAuthentication || auth0Service.isAuthenticated()) {
      return !permissions?.isPartner
    }
    return Consts.SSO_FALLBACK_ENABLED && userService.isFallbackAuthenticated()
  }, [isCurrentUserLoading, roles, company, permissions, featuresEnabled])

  const clickHandler = useCallback(() => {
    lastClickTime.current = Date.now()
    if (isTimeoutEnabled) {
      sessionService.set('session-timeout-at', String(Date.now() + SHOW_SESSION_TIMEOUT_IN_MILLIES))
    }
  }, [isTimeoutEnabled])

  const checkOnTimeouts = useCallback(
    ({ context }: { context: string }) => {
      // Check if we need to show the warning
      if (!modalIsOpen(modalId) && isTimeoutEnabled) {
        if (lastClickTime.current + SHOW_SESSION_TIMEOUT_IN_MILLIES <= Date.now()) {
          const expiresAt = moment().add(Consts.CONFIRM_SESSION_TIMEOUT_MODAL_IN_MINUTES, 'minutes')
          logging.logInfo('Showing session timeout warning', {
            context,
            inactivity_threshhold: Consts.CONFIRM_SESSION_TIMEOUT_MODAL_IN_MINUTES * 60 * 1000,
            inactivity_time: Date.now() - lastClickTime.current,
          })

          openModal(
            <LogoutWarning
              expiresAt={expiresAt}
              id={modalId}
              lastClickTime={lastClickTime.current}
              onContinue={handleContinue}
              onLogout={logout}
            />,
            modalId
          )
        }
      }

      // Check if we need to refresh
      if (lastClickTime.current + Consts.INACTIVITY_REFRESH_MINUTES * 60 * 1000 <= Date.now()) {
        logging.logInfo('Immediate refresh due to inactivity', {
          context,
          inactivity_threshhold: Consts.INACTIVITY_REFRESH_MINUTES * 60 * 1000,
          inactivity_time: Date.now() - lastClickTime.current,
        })
        location.reload()
      }
    },
    [isTimeoutEnabled, modalIsOpen, openModal]
  )

  useEffect(() => {
    const checkOnTimeoutsVisibility = () => checkOnTimeouts({ context: 'documentVisibilty' })
    window.addEventListener('mousedown', clickHandler, { passive: true }) // catches touchscreen presses as well
    window.addEventListener('touchstart', clickHandler, { passive: true }) // catches touchscreen swipes as well
    document.addEventListener('keydown', clickHandler, { passive: true })
    document.addEventListener('visibilitychange', checkOnTimeoutsVisibility)
    return () => {
      window.removeEventListener('mousedown', clickHandler)
      window.removeEventListener('touchstart', clickHandler)
      document.removeEventListener('keydown', clickHandler)
      document.removeEventListener('visibilitychange', checkOnTimeoutsVisibility)
    }
  }, [checkOnTimeouts, clickHandler, isTimeoutEnabled])

  // Set up timeout that checks every minute in case the computer has gone to sleep
  useEffect(() => {
    const intervalId = workerTimers.setInterval(
      () => checkOnTimeouts({ context: 'interval' }),
      60 * 1000
    )

    return () => {
      workerTimers.clearInterval(intervalId)
    }
  }, [checkOnTimeouts, isTimeoutEnabled, openModal])
}

export default useSessionTracker
