import datadog from 'shared/utils/logging/integrations/datadog'
import UserStore from 'stores/user_store.coffee'
import utils from 'shared/utils/utils.coffee'
import { RelayJSONParseErrorType } from 'shared/utils/relay'
import {
  TYPE_ERROR,
  REFERENCE_ERROR,
  isBrowserExtension,
  shouldSwallowError,
  logErrorToDatadog,
} from 'shared/utils/logging/utils/rollbarUtils'

const transform = (payload: Rollbar.Payload) => {
  if (window.FS?.getCurrentSessionURL) {
    // eslint-disable-next-line no-param-reassign
    payload.fullStoryUrl = window.FS.getCurrentSessionURL(true)
    // eslint-disable-next-line no-param-reassign
    payload.datadogUrl = `https://app.datadoghq.com/logs?query=%40user.id:${
      UserStore.getUser()?.ssid
    }&from_ts=${Date.now() - 1000 * 60}&to_ts=${Date.now()}&live=false`
  }
}

const configuration: Rollbar.Configuration = {
  // Ignore certain types of messages
  // see https://docs.rollbar.com/docs/reduce-noisy-javascript-errors#section-ignore-certain-types-of-messages
  // just add some message to an array `ignoredMessages: ["Can't find Clippy.bmp. The end is nigh."]`
  ignoredMessages: [],
  transform,
  onSendCallback(isUncaught, args, payload) {
    logErrorToDatadog(payload)
  },
  // Ignore noisy errors on the client-side
  // see https://docs.rollbar.com/docs/reduce-noisy-javascript-errors#section-ignore-noisy-errors-on-the-client-side
  checkIgnore(isUncaught, args, payload) {
    // Code here to determine whether or not to send the payload to the Rollbar API
    // return true to ignore the payload
    if (utils.isMobile()) {
      logErrorToDatadog(payload, true)
      return true
    }

    if (isUncaught) {
      const trace = payload?.body?.trace
      const exception = trace?.exception
      const browser = payload?.client?.javascript?.browser

      /* NOTE:
      Ignore errors if the trace filename is unhandled and thrown from third party scripts originating
      from the C1_CHAT_ENDPOINT domain */
      try {
        const traceHostName = new URL(trace?.frames?.[0]?.filename || '').hostname
        const c1ChatHostName = new URL(window.ENV.C1_CHAT_ENDPOINT || '').hostname
        if (c1ChatHostName && traceHostName && c1ChatHostName === traceHostName) {
          datadog.logError(`FE Error: error in c1conversations.io script`, {
            error: payload?.body,
            browser,
            filename: trace?.frames?.[0]?.filename,
          })

          return true
        }
      } catch {
        /* empty */
      }

      if (exception?.class === TYPE_ERROR && exception?.message === 'The request timed out.') {
        return true
      }
      if (/^script error.?$/i.test(exception?.message || '')) {
        logErrorToDatadog(payload)
        return true
      }
      // Regex Match Example: 'Rollbar_Ignore_ENG-40304: A known error occured'
      const ROLLBAR_IGNORE_PATTERN = /ROLLBAR_IGNORE_ENG-\d+/i
      if (ROLLBAR_IGNORE_PATTERN.test(`${exception?.description}`)) {
        return true
      }
      if (exception?.message === 'ResizeObserver loop limit exceeded') {
        return true
      }
      if (exception?.message === 'Cannot redefine property: googletag') {
        return true
      }
      if (
        exception?.class === TYPE_ERROR &&
        exception?.message === 'NetworkError when attempting to fetch resource.' &&
        browser?.includes('Firefox')
      ) {
        return true
      }
      if (exception?.message === RelayJSONParseErrorType.newErrorMessage) {
        // TODO: Fix this here: https://swoopme.atlassian.net/browse/ENG-45808
        // ignores this rollbar issue https://swoopme.atlassian.net/browse/ENG-22641 because is already sent to DataDog
        return true
      }
      if (/__gCrWeb.edgeTranslate/i.test(exception?.message || '')) {
        return true
      }
      if (exception?.message === 'Load failed [fetchJobPiiQuery]') {
        // This will go away once we migrate to graphql
        logErrorToDatadog(payload)
        return true
      }

      if (exception?.message === 'Missing jobEnteredTab subscription data') {
        // There is an ongoing investigation to determine the root cause of this error
        // ENG-41265 was done to solve this, but did not work, see ticket for follow up
        logErrorToDatadog(payload)
        return true
      }

      if (exception?.description === 'The string did not match the expected pattern.') {
        // Introduced with the new invoices; cleanup ticket: ENG-51091
        logErrorToDatadog(payload)
        return true
      }

      if (exception?.message === "null is not an object (evaluating 'bgColor.replace')") {
        // There's no apparent cause for this error, and it's not affecting the user experience
        // The only clue is that it's happening only for Safari
        logErrorToDatadog(payload)
        return true
      }

      if (isBrowserExtension(trace)) {
        const extentionErrorConditions = [
          // ignores this rollbar issue https://rollbar.com/SwoopMeInc/SwoopProduction/items/9439/
          {
            class: TYPE_ERROR,
            message: "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
          },
          // ignores rollbar issue with Browser Plugin - ibFindAllVideos is not defined
          {
            class: REFERENCE_ERROR,
            message: 'ibFindAllVideos is not defined',
          },
          // ignores rollbar issue with Browser Plugin - ibPauseAllVideos is not defined
          {
            class: REFERENCE_ERROR,
            message: 'ibPauseAllVideos is not defined',
          },
          {
            class: TYPE_ERROR,
            message: "undefined is not an object (evaluating 'n.length')",
          },
          {
            class: TYPE_ERROR,
            message: "null is not an object (evaluating 'a.getElementsByTagName')",
          },
        ]

        const inIgnoreList = extentionErrorConditions.some(
          (condition) =>
            exception?.class === condition.class && exception?.message === condition.message
        )

        if (!inIgnoreList) {
          /* Move to datadog just in case */
          logErrorToDatadog(payload)
        }

        return true
      }
      if (shouldSwallowError(trace?.extra)) {
        return true
      }
    }

    if (shouldSwallowError(payload?.body?.message?.extra)) {
      return true
    }

    return false
  },
}

// @ts-expect-error
if (window?.Rollbar?.configure) {
  // We don't want some issues to appear in rollbar at all
  // For example something generated from a plugin of a user that doesn't have an impact on our system
  Rollbar.configure(configuration)
}

export default configuration
