// eslint-disable-next-line no-restricted-imports
import { graphql, useSubscription } from 'relay-hooks'
import { useMemo } from 'react'

import logging from 'shared/utils/logging'
import useCurrentUser from 'web-client/hooks/useCurrentUser'
import { playSoundEffect } from 'web-client/utils/soundEffect'
import { removeNotification, sendNotification } from 'utilsLibrary/notificationUtils'
import type { Type as NotificationType } from 'design-system/components/Notifications/notificationUtils'
import { localize } from 'shared/utils/languageUtils'
import type { useNotificationsCreatedSubscription } from './__generated__/useNotificationsCreatedSubscription.graphql'
import type { useNotificationsRemovedSubscription } from './__generated__/useNotificationsRemovedSubscription.graphql'

const notificationCreatedSubscription = graphql`
  subscription useNotificationsCreatedSubscription {
    notificationCreated {
      template
      variables
      level
      playSoundEffect
      sticky
      timeout
    }
  }
`
const notificationRemovedSubscription = graphql`
  subscription useNotificationsRemovedSubscription {
    notificationRemoved {
      template
      variables
    }
  }
`

const handleError = (error: Error) => {
  logging.logError('Notifcation subscription error', error)
}

const handleCompleted = () => {
  logging.logInfo('Notification subscription closed by server', {})
}

const useNotifications = () => {
  const { isLoggedIn } = useCurrentUser()

  useSubscription<useNotificationsCreatedSubscription>(
    useMemo(
      () => ({
        subscription: notificationCreatedSubscription,
        variables: {},
        onError: handleError,
        onNext: (response) => {
          if (!response?.notificationCreated) {
            return
          }

          sendNotification(
            localize(
              // @ts-expect-error - Fix in https://swoopme.atlassian.net/browse/ENG-56329
              response.notificationCreated.template,
              response.notificationCreated.variables as Record<string, string>
            ),
            response.notificationCreated.level as NotificationType,
            {
              sticky: response.notificationCreated.sticky,
              timeout: response.notificationCreated.timeout,
            }
          )

          if (response.notificationCreated.playSoundEffect) {
            playSoundEffect()
          }
        },
        onCompleted: handleCompleted,
      }),
      []
    ),
    { skip: !isLoggedIn }
  )

  useSubscription<useNotificationsRemovedSubscription>(
    useMemo(
      () => ({
        subscription: notificationRemovedSubscription,
        variables: {},
        onError: handleError,
        onNext: (response) => {
          if (!response?.notificationRemoved) {
            return
          }

          removeNotification(
            localize(
              // @ts-expect-error - Fix in https://swoopme.atlassian.net/browse/ENG-56329
              response.notificationRemoved.template,
              response.notificationRemoved.variables as Record<string, string>
            )
          )
        },
        onCompleted: handleCompleted,
      }),
      []
    ),
    { skip: !isLoggedIn }
  )
}

export default useNotifications
